import React from 'react';

import { Container, Icon } from './style';

type MenuItemProps = {
  icon: string;
  children: string;
  path: string;
  $active?: boolean;
};

const MenuItem = ({ icon, children, path, $active }: MenuItemProps) => {
  return (
    <Container to={path} $active={$active}>
      <Icon src={icon} />
      {children}
    </Container>
  );
};

export default MenuItem;
