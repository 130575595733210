import { toast } from 'react-toastify';

import { AxiosError } from 'axios';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';

import { convertStringToContentState } from '~/components/Editor/helpers';
import { DungeonBasicResponse, HuntBasicResponse } from '~/types/responses';

export const roundNumber = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const formatNumberToK = (number: number, startAt = 1000) => {
  let num = number;
  const negative = number < 0;
  if (negative) {
    num *= -1;
  }
  let formatted = '';
  if (num / 1000000 >= 1) {
    formatted = `${roundNumber(num / 1000000).toString()}kk`;
  } else if (num / startAt >= 1) {
    formatted = `${roundNumber(num / 1000).toString()}k`;
  } else {
    formatted = `${num.toString()}`;
  }
  if (negative) {
    formatted = `-${formatted}`;
  }
  return formatted;
};

export const formatNumberToGold = (number: number) => {
  let num = number;
  const negative = number < 0;
  if (negative) {
    num *= -1;
  }
  let formatted = '';
  if (num / 1000000 >= 1) {
    formatted = `${roundNumber(num / 1000000).toString()}kk`;
  } else if (num / 1000 >= 1) {
    formatted = `${roundNumber(num / 1000).toString()}k`;
  } else {
    formatted = `${num.toString()}gp`;
  }
  if (negative) {
    formatted = `-${formatted}`;
  }
  return formatted;
};

export const formatTeamSize: { [key: string]: string } = {
  solo: 'Solo',
  duo: 'Duo (2 players)',
  squad: 'Squad (4 players)',
};

export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatVocation = (vocation: string, cityName?: string) => {
  let formatedVocation = vocation.replace('_', ' ') || '';

  if (vocation === 'no_vocation' && cityName === 'Rookgaard') {
    formatedVocation = 'rookstayer';
  }

  return formatedVocation;
};

export const formatTimeAgo = (date: string, hideSuffix = false): string => {
  const parsedDate = moment(date).fromNow(hideSuffix);
  return parsedDate;
};

export const formatDate = (date: string, format?: string): string => {
  const parsedDate = moment(date).format(format || 'LLL');
  return parsedDate;
};

export const slugify = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/\//g, '-') // Replace backslashes with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-'); // Replace multiple - with single -
};

export const sanitizeNumberString = (
  value: string,
  onlyPositive?: boolean,
  onlyInteger?: boolean
) => {
  let replacedVal = value;

  const positiveIntegerRegex = /[^0-9]/g;
  const positiveDecimalRegex = /[^0-9.]/g;
  const integerRegex = /(?!^-)[^0-9]/g;
  const decimalRegex = /(?!^-)[^0-9.]/g;

  if (onlyPositive && onlyInteger) {
    replacedVal = value.replace(positiveIntegerRegex, '');
  } else if (onlyPositive) {
    replacedVal = value.replace(positiveDecimalRegex, '');
  } else if (onlyInteger) {
    replacedVal = value.replace(integerRegex, '');
  } else {
    replacedVal = value.replace(decimalRegex, '');
  }

  return replacedVal.replace(/(\..*)\./g, '$1');
};

export const convertNumberToString = (number?: number | null) => {
  if (number === 0) {
    return '0';
  }

  if (!number) {
    return '';
  }

  return number.toString();
};

// TODO: Reuse method where it's possible
export const popErrorMessages = (
  err: AxiosError | unknown,
  genericMessage: string
) => {
  const coercedErr = err as AxiosError;
  const errorMessages = coercedErr?.response?.data.error?.message;

  if (errorMessages && errorMessages.length > 0) {
    errorMessages.forEach((message: string) => toast.error(message));
  } else {
    toast.error(genericMessage);
  }
};

export const handleApiErrors = (
  err: AxiosError | unknown,
  genericMessage: string,
  handleUnauthorizedError?: () => void,
  handleGenericErrors?: () => void
) => {
  const coercedErr = err as AxiosError;
  const unauthorized = coercedErr?.response?.status === 401;
  if (unauthorized) {
    if (handleUnauthorizedError) handleUnauthorizedError();
  } else if (handleGenericErrors) {
    handleGenericErrors();
  } else {
    popErrorMessages(coercedErr, genericMessage);
  }
};

export const getHuntSlug = (hunt: HuntBasicResponse) => {
  const formatedVocation = formatVocation(
    hunt.vocation,
    hunt.dungeon?.city.name
  );
  let string = formatedVocation;

  if (hunt.dungeon) string += ` ${hunt.dungeon.title}`;
  if (hunt.title) string += ` ${hunt.title}`;

  return slugify(string);
};

export const getRandomString = () => {
  return (Math.random() + 1).toString(36).substring(7);
};

export const camelCaseToReadable = (string: string) => {
  let formatedStr = string.replace(
    /[A-Z]/g,
    (letter) => ` ${letter.toLowerCase()}`
  );
  formatedStr = formatedStr.substring(1);
  return formatedStr;
};

export const getSampleDungeon = (): DungeonBasicResponse => ({
  id: 10000000,
  accountStatus: '',
  entranceCoordinates: null,
  entranceDirections: null,
  city: { id: 1, name: '', type: 'City' },
  group: null,
  title: '',
  type: 'Dungeon',
  wikiId: null,
  createdAt: '',
  updatedAt: '',
});

export function getHtmlInstructions(instructions: string) {
  return stateToHTML(convertStringToContentState(instructions)).replaceAll(
    '<p><br></p>',
    '<p></p>'
  );
}
