import React from 'react';

import Footer from '~/components/Footer';
import Header from '~/components/Header';
import Sidebar from '~/components/Sidebar';
import useMediaQuery from '~/hooks/mediaQuery';
import { MediaQuery } from '~/styles/variables';

import Content from './components/Content';
import { Container } from './style';

export type DefaultLayoutProps = {
  children: React.ReactNode;
  bgColor?: string;
};

const DefaultLayout = ({ children, bgColor }: DefaultLayoutProps) => {
  const isTabletUp = useMediaQuery(MediaQuery.TABLET_UP);

  return (
    <Container>
      {isTabletUp && <Sidebar />}
      <Header />
      <Content bgColor={bgColor}>
        {children}
        <Footer />
      </Content>
    </Container>
  );
};

export default DefaultLayout;
