import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { eraseCookie } from '~/helpers/cookies';
import useMediaQuery from '~/hooks/mediaQuery';
import axios from '~/services/axios';
import { logoutUser } from '~/stores/user/userActions';
import { MediaQuery } from '~/styles/variables';
import { StoreProps } from '~/types';

import { OutlinedButton, OutlinedLink } from '../../style';
import {
  Container,
  Dropdown,
  DropdownButton,
  DropdownLink,
  Icon,
} from './style';

const MyAccountWidget = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery(MediaQuery.MOBILE);

  const [loadingLogout, setLoadingLogout] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const currentUser = useSelector(
    (store: StoreProps) => store.user.currentUser
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const handleLogout = async () => {
    setLoadingLogout(true);
    setIsOpen(false);
    try {
      await axios.securedAxiosInstance.delete('/users/logout');
    } catch (err) {
      console.error(err);
    } finally {
      eraseCookie('csrf');
      eraseCookie('currentUser');
      dispatch(logoutUser());
      history.push('/');
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    const clickedOutside =
      containerRef.current &&
      !containerRef.current.contains(e.target as HTMLElement);

    if (clickedOutside) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  if (currentUser) {
    return (
      <Container ref={containerRef}>
        {isMobile ? (
          <OutlinedButton onClick={() => setIsOpen(!isOpen)}>
            <Icon />
          </OutlinedButton>
        ) : (
          <OutlinedButton onClick={() => setIsOpen(!isOpen)}>
            {loadingLogout ? 'Logging out...' : currentUser.username}
          </OutlinedButton>
        )}

        {isOpen && (
          <Dropdown>
            <DropdownButton onClick={handleLogout}>Logout</DropdownButton>
          </Dropdown>
        )}
      </Container>
    );
  }

  return (
    <Container ref={containerRef}>
      {isMobile ? (
        <OutlinedButton onClick={() => setIsOpen(!isOpen)}>
          <Icon />
        </OutlinedButton>
      ) : (
        <OutlinedLink to="/login">Login</OutlinedLink>
      )}

      {isOpen && (
        <Dropdown>
          <DropdownLink to="/login">Login</DropdownLink>
        </Dropdown>
      )}
    </Container>
  );
};

export default MyAccountWidget;
