import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ScrollToTop from './components/ScrollToTop';
import Routes from './routes';
import './styles/tailwind.css';

const App = () => (
  <>
    <ScrollToTop />
    <ToastContainer />
    <Routes />
  </>
);

export default App;
