import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { popErrorMessages } from '~/helpers';
import axios from '~/services/axios';

import { Container, OutlinedButton } from './style';

const UpdateLootsWidget = () => {
  const [loading, setLoading] = useState(false);

  const handleUpdateLoots = async () => {
    try {
      setLoading(true);
      await axios.securedAxiosInstance.post('/admin/notable-loots');
      toast.success('Notable loots has been updated');
    } catch (err) {
      popErrorMessages(
        err,
        'Error to update pending loots. Please, try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <OutlinedButton onClick={handleUpdateLoots} disabled={loading}>
        {loading ? 'Updating...' : 'Update loots'}
      </OutlinedButton>
    </Container>
  );
};

export default UpdateLootsWidget;
