import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 120px;
  height: 100%;
  background-color: #c73c3c;
  top: 0;
  overflow: auto;
`;

export const LogoLink = styled(Link)`
  display: block;
  outline-offset: -2px;
  outline-color: #fff;
`;

export const Logo = styled.img`
  width: 100%;
  padding: 23px 28px 8px;
`;

export const Menu = styled.nav``;
