import React from 'react';
import { useHistory } from 'react-router-dom';

import channelsIcon from '~/assets/images/icons/sidebar/channels.png';
import creaturesIcon from '~/assets/images/icons/sidebar/creatures.png';
import dungeonsIcon from '~/assets/images/icons/sidebar/dungeons.png';
import eventsIcon from '~/assets/images/icons/sidebar/events.png';
import huntFinderIcon from '~/assets/images/icons/sidebar/hunts.png';
import itemsIcon from '~/assets/images/icons/sidebar/items.png';
import moderateIcon from '~/assets/images/icons/sidebar/moderate.png';
import usersIcon from '~/assets/images/icons/sidebar/users.png';
import logoImg from '~/assets/images/logo-footer.png';

import MenuItem from './components/MenuItem';
import { Container, Logo, LogoLink, Menu } from './style';

const Sidebar = () => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  const isModerateActive =
    currentPath.includes('/moderate') || currentPath === '/';
  const isHuntsActive =
    currentPath.includes('/hunts') && !currentPath.includes('/moderate/hunts');

  return (
    <Container>
      <LogoLink to="/">
        <Logo src={logoImg} />
      </LogoLink>
      <Menu>
        <MenuItem
          path="/moderate/hunts"
          $active={isModerateActive}
          icon={moderateIcon}
        >
          Moderate
        </MenuItem>
        <MenuItem path="/hunts" $active={isHuntsActive} icon={huntFinderIcon}>
          Hunts
        </MenuItem>
        <MenuItem
          path="/dungeons"
          $active={currentPath.includes('/dungeons')}
          icon={dungeonsIcon}
        >
          Dungeons
        </MenuItem>
        <MenuItem
          path="/items"
          $active={currentPath.includes('/items')}
          icon={itemsIcon}
        >
          Items
        </MenuItem>
        <MenuItem
          path="/creatures"
          $active={currentPath.includes('/creatures')}
          icon={creaturesIcon}
        >
          Creatures
        </MenuItem>
        <MenuItem
          path="/users"
          $active={currentPath.includes('/users')}
          icon={usersIcon}
        >
          Users
        </MenuItem>
        <MenuItem
          path="/events"
          $active={currentPath.includes('/events')}
          icon={eventsIcon}
        >
          Events
        </MenuItem>
        <MenuItem
          path="/youtube-channels"
          $active={currentPath.includes('/youtube-channels')}
          icon={channelsIcon}
        >
          Channels
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default Sidebar;
