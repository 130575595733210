import styled from 'styled-components';

import { FontFamily } from '~/styles/variables';

export const Container = styled.div`
  position: relative;
  display: flex;
`;

export const OutlinedButton = styled.button`
  display: block;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid #fff;
  margin: 0;
  padding: 4px 23px;
  border-radius: 4px;
  transition: 0.3s all;
  letter-spacing: 1px;
  margin-right: 15px;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-family: ${FontFamily.DIN};
  outline-color: #fff;
  background-color: transparent;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #fff;
    color: #000;
  }
`;
