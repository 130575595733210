import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const ScrollToTop = () => {
  // FIXME: search type
  const { search } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [pathname, search]);

  return null;
};

export default ScrollToTop;
