const env = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  CLIENT_WEB_URL: process.env.REACT_APP_CLIENT_WEB_URL,
  GOOGLE_AUTH_CLIENT_ID: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
  PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
};

export const checkEnvironment = () => {
  Object.keys(env).forEach((key) => {
    if (!(`REACT_APP_${key}` in process.env)) {
      throw new Error(`Environment variable REACT_APP_${key} is undefined!`);
    }
  });
};

export default env;
