import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex: auto;
  flex-shrink: 0;
  background-color: #f9f9f9;
  border-top: 1px solid #dedede;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1320px;
  padding: 40px 90px;
`;

export const CardList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
`;
