import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { FontFamily } from '~/styles/variables';

export const Icon = styled.img`
  width: 30px;
  margin-bottom: 9px;
`;

const activeItemProps = css`
  opacity: 1;
`;

export const Container = styled(Link)<{ $active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-family: ${FontFamily.DIN};
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 13px;
  padding: 11px 0;
  color: #fff;
  transition: 0.3s all;
  opacity: 0.7;
  outline-offset: -2px;
  outline-color: #fff;

  &:hover,
  &:focus {
    opacity: 1;
  }

  ${(props) => props.$active && activeItemProps};
`;
