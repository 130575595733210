import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import createRootReducer from './stores/rootReducer';

export const history = createBrowserHistory();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const configureStore = (preloadedState?: any) => {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(routerMiddleware(history)))
  );

  return store;
};

export default configureStore;
