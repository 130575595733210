import React from 'react';

import MyAccountWidget from './components/MyAccountWidget';
import UpdateLootsWidget from './components/UpdateLootsWidget';
import { Container, Corner, OutlinedLink } from './style';

const Header = () => {
  return (
    <Container>
      <Corner>
        <OutlinedLink to="/changelog">Changelog</OutlinedLink>
        <UpdateLootsWidget />
      </Corner>
      <Corner>
        <MyAccountWidget />
      </Corner>
    </Container>
  );
};

export default Header;
