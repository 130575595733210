import React from 'react';

import { Container } from './style';

type ContentProps = {
  children: React.ReactNode;
  bgColor?: string;
};

const Content = ({ children, bgColor }: ContentProps) => {
  return <Container bgColor={bgColor}>{children}</Container>;
};

export default Content;
