import React from 'react';

import { Container } from './style';

export type BlankLayoutProps = {
  children: React.ReactNode;
};

const BlankLayout = ({ children }: BlankLayoutProps) => {
  return <Container>{children}</Container>;
};

export default BlankLayout;
