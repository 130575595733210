import React from 'react';

import { Container } from './style';

type LoadingProps = {
  className?: string;
};

const Loading = ({ className }: LoadingProps) => {
  return (
    <Container className={className}>
      <div />
      <div />
      <div />
    </Container>
  );
};

export default Loading;
