import axios from 'axios';

import configuration from '~/config';
import { eraseCookie, getCookie, setCookie } from '~/helpers/cookies';

const API_URL = configuration.apiBaseUrl;

const plainAxiosInstance = axios.create({
  baseURL: API_URL,
  // Used for optional authentication routes, like '/hunts' that returns
  // isFavorited/isUpvoted when the route is authenticated
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

const securedAxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    authenticated: true,
  },
});

securedAxiosInstance.interceptors.request.use((config) => {
  const method = config.method!.toUpperCase();
  if (method !== 'OPTIONS' && method !== 'GET') {
    config.headers = {
      ...config.headers,
      'X-CSRF-TOKEN': getCookie('csrf'),
    };
  }
  return config;
});

securedAxiosInstance.interceptors.response.use(undefined, async (error) => {
  if (
    error.response &&
    error.response.config &&
    error.response.status === 401
  ) {
    return plainAxiosInstance
      .post(
        '/users/refresh',
        {},
        { headers: { 'X-CSRF-TOKEN': getCookie('csrf') } }
      )
      .then((response) => {
        const { csrf, user } = response.data.data;
        setCookie('csrf', csrf);
        setCookie(
          'currentUser',
          JSON.stringify({
            id: user.id,
            username: user.username,
          })
        );
        const retryConfig = error.response.config;
        retryConfig.headers['X-CSRF-TOKEN'] = getCookie('csrf');
        return plainAxiosInstance.request(retryConfig);
      })
      .catch((err) => {
        eraseCookie('csrf');
        eraseCookie('currentUser');
        console.error(err);
        return Promise.reject(err);
      });
  }

  return Promise.reject(error);
});

export default { securedAxiosInstance, plainAxiosInstance };
