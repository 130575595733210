import env from './config/environment';

const stage = process.env.NODE_ENV;

const dev = {
  apiBaseUrl: 'http://localhost:3000/api/v1',
};

const prod = {
  apiBaseUrl: env.API_BASE_URL,
};

const config = stage === 'development' ? dev : prod;

export default config;
