import { getCookie } from '~/helpers/cookies';
import { LocalStorageUserProps, UserStateProps } from '~/types';

const currentUserCookie = getCookie('currentUser');
const currentUser = currentUserCookie ? JSON.parse(currentUserCookie) : null;

const initialState: UserStateProps = {
  currentUser,
  csrf: getCookie('csrf'),
};

interface ChangeUserProps {
  type: 'CHANGE_CURRENT_USER';
  payload: LocalStorageUserProps;
}

interface ChangeCsrfTokenProps {
  type: 'CHANGE_CSRF_TOKEN';
  payload: string;
}

interface LogoutProps {
  type: 'LOGOUT_USER';
}

type UserActionsProps = ChangeUserProps | ChangeCsrfTokenProps | LogoutProps;

const userReducer = (
  state = initialState,
  action: UserActionsProps
): UserStateProps => {
  switch (action.type) {
    case 'CHANGE_CURRENT_USER':
      return {
        ...state,
        currentUser: action.payload,
      };
    case 'CHANGE_CSRF_TOKEN':
      return {
        ...state,
        csrf: action.payload,
      };
    case 'LOGOUT_USER':
      return {
        ...state,
        currentUser: null,
        csrf: null,
      };
    default:
      return state;
  }
};

export default userReducer;
