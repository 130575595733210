export enum Color {}

export enum Device {
  TABLET = '768px',
  DESKTOP = '1140px',
  LARGE_DESKTOP = '1536px',
}

export enum MediaQuery {
  MOBILE = '(max-width: 767px)',
  MINI_MOBILE = '(max-width: 767px) and (max-height: 620px)',
  LARGE_MOBILE_UP = '(min-height: 692px)',
  TABLET_DOWN = '(max-width: 1139px)',
  TABLET_UP = '(min-width: 768px)',
  DESKTOP_DOWN = '(max-width: 1535px)',
  DESKTOP_UP = '(min-width: 1140px)',
  LARGE_DESKTOP = '(min-width: 1536px)',
}

export enum FontFamily {
  RUBIK = 'Rubik, Arial, Helvetica, sans-serif',
  DIN = 'DIN, Arial, Helvetica, sans-serif',
  MONOSPACE = 'monospace, Arial, Helvetica, sans-serif',
}

export enum FontWeight {
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  SEMI_BOLD = 600,
  BOLD = 700,
  EXTRA_BOLD = 800,
}
