import { createGlobalStyle } from 'styled-components';

import GridCSS from './grid';
import { FontFamily } from './variables';

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  ${'@-ms-viewport'} {
    width: device-width;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    width: 100%;
    height: 100%;
  }

  body {
    color: #1d1d1d;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: ${FontFamily.RUBIK};
    font-size: 14px;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  a {
    color: #1890ff;
    text-decoration: none;
    transition: 0.3s all;

    &:hover {
      opacity: 0.8;
    }
  }

  ${GridCSS}

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  .ReactModal__Content {
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;
    overflow: visible !important;
  }

  .fc-scroller-liquid-absolute {
    overflow: hidden !important;
  }

  .fc .fc-col-header-cell-cushion {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
    color: #b2b2b2;
  }

  .fc .fc-button:not(:disabled) {
    cursor: pointer;
    border-color: #178fff;
    background-color: transparent;
    color: #178fff;
    font-size: 13px;
  }

  .fc .fc-button-primary:disabled {
    border-color: #777777;
    background-color: transparent;
    color: #777;
    font-size: 13px;
  }

  .fc .fc-button-primary:not(:disabled):active {
    border-color: #178fff;
    background-color: #178fff;
    color: #fff;
    font-size: 13px;
  }

  .fc-h-event .fc-event-main {
    cursor: help;
  }

  .fc .fc-daygrid-day-number {
    padding: 4px;
    color: #000;
  }

  .Toastify__toast {
    font-family: ${FontFamily.RUBIK};
    padding: 8px 20px;
  }

  .Toastify__close-button {
    margin-right: -9px;
  }

  .public-DraftEditor-content {
    line-height: 23px;
  }

  .public-DraftEditor-content ul,
  .public-DraftEditor-content ol {
    margin: 10px 0;
  }

  .public-DraftEditor-content > div:first-child > ul:first-child,
  .public-DraftEditor-content > div:first-child > ol:first-child {
    margin-top: 0;
  }

  .group-sections-modal {
    z-index: 1;
    position: relative;
  }

  .group-sections-action-modal {
    z-index: 2;
    position: relative;
  }
`;

export default GlobalStyle;
