import React from 'react';

import { Container, Paragraph } from './style';

const Footer = () => {
  return (
    <Container>
      <Paragraph>
        Intibia.com © {new Date().getFullYear()}. All rights reserved.
      </Paragraph>
    </Container>
  );
};

export default Footer;
