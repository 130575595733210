import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

export const convertEditorStateToString = (state: EditorState): string => {
  const content = state.getCurrentContent();
  const rawContent = convertToRaw(content);
  const stringContent = JSON.stringify(rawContent);
  return stringContent;
};

export const convertStringToBlockArray = (rawState: string) => {
  try {
    const parsedContent = JSON.parse(rawState);
    const convertedContent = convertFromRaw(parsedContent);
    return convertedContent.getBlocksAsArray();
  } catch {
    const emptyContent = EditorState.createEmpty()
      .getCurrentContent()
      .getBlocksAsArray();
    return emptyContent;
  }
};

export const convertStringToContentState = (string: string) => {
  try {
    const content = JSON.parse(string);
    return EditorState.createWithContent(
      convertFromRaw(content)
    ).getCurrentContent();
  } catch {
    const emptyContent = EditorState.createEmpty().getCurrentContent();
    return emptyContent;
  }
};
