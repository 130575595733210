import React from 'react';

import Loading from '~/components/Loading';

import { Container } from './style';

const LoadingPage = () => {
  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default LoadingPage;
