// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/DIN-RegularAlternate.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/DIN-RegularAlternate.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/DIN-BoldAlternate.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/DIN-BoldAlternate.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/fonts/Rubik-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/fonts/Rubik-Light.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../assets/fonts/Rubik-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../assets/fonts/Rubik-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../assets/fonts/Rubik-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../assets/fonts/Rubik-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../assets/fonts/Rubik-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_11___ = require("../assets/fonts/Rubik-Bold.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'DIN';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n  font-weight: 400;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'DIN';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n  font-weight: 700;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'Rubik';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff');\n  font-weight: 300;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'Rubik';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff');\n  font-weight: 400;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'Rubik';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('woff');\n  font-weight: 500;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'Rubik';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format('woff');\n  font-weight: 700;\n  font-style: normal;\n  font-display: swap;\n}\n", ""]);
// Exports
module.exports = exports;
