import React, { ComponentProps, ElementType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { StoreProps } from '~/types';

type PrivateRouteProps = RouteProps & {
  component: ElementType;
  layout: ElementType;
  defaultTab?: string;
};

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}: PrivateRouteProps) => {
  const csrf = useSelector((store: StoreProps) => store.user.csrf);

  const finalComponent = (props: ComponentProps<ElementType>) => {
    if (csrf) {
      return (
        <Layout>
          <Component {...props} {...rest} />
        </Layout>
      );
    }
    return <Redirect to="/login" />;
  };

  return <Route {...rest} render={finalComponent} />;
};

export default PrivateRoute;
