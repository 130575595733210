import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';

import App from './App';
import configureStore, { history } from './configureStore';
import './styles/fonts.css';
import GlobalStyle from './styles/global';

const store = configureStore();

Modal.setAppElement('#root');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <QueryClientProvider client={queryClient}>
        <App />
        <GlobalStyle />
      </QueryClientProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
