import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { FontFamily } from '~/styles/variables';

export const Container = styled.header`
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
  width: calc(100% - 120px);
  height: 50px;
  top: 0;
  margin-left: 120px;
  z-index: 3;
  justify-content: space-between;
  align-items: center;
  padding: 5px 22px;
  font-family: ${FontFamily.DIN};
`;

export const Corner = styled.div`
  display: flex;
`;

export const Separator = styled.span`
  margin: 0 15px;
  color: #fff;
`;

const OutlinedElement = css`
  display: block;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid #fff;
  padding: 4px 23px;
  border-radius: 4px;
  transition: 0.3s all;
  letter-spacing: 1px;
  margin-right: 15px;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-family: ${FontFamily.DIN};
  outline-color: #fff;

  &:hover,
  &:focus {
    background-color: #fff;
    color: #000;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const OutlinedButton = styled.button`
  ${OutlinedElement}
  margin: 0;
  background-color: transparent;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #fff;
    color: #000;
  }
`;

export const OutlinedLink = styled(Link)`
  ${OutlinedElement}
`;
