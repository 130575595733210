import { LocalStorageUserProps } from '~/types';

type ChangeCurrentUserPayloadProps = LocalStorageUserProps;
type ChangeCsrfTokenPayloadProps = string;

type UserActionProps = {
  type: string;
  payload?: ChangeCurrentUserPayloadProps | ChangeCsrfTokenPayloadProps;
};

export const changeCurrentUser = (
  currentUser: LocalStorageUserProps
): UserActionProps => ({
  type: 'CHANGE_CURRENT_USER',
  payload: currentUser,
});

export const changeCsrfToken = (token: string): UserActionProps => ({
  type: 'CHANGE_CSRF_TOKEN',
  payload: token,
});

export const logoutUser = (): UserActionProps => ({
  type: 'LOGOUT_USER',
});
