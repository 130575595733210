import React, { Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom';

import BlankLayout from '~/pages/_layout/Blank';
import DefaultLayout from '~/pages/_layout/Default';
import LoadingPage from '~/pages/LoadingPage';

import GuestRoute from './GuestRoute';
import PrivateRoute from './PrivateRoute';

const Hunts = lazy(() => import('~/pages/Hunts'));
const Login = lazy(() => import('~/pages/Login'));
const NotFound = lazy(() => import('~/pages/NotFound'));
const PendingHunt = lazy(() => import('~/pages/Hunts/pages/PendingHunt'));
const EditHunt = lazy(() => import('~/pages/Hunts/pages/EditHunt'));
const ViewHunt = lazy(() => import('~/pages/Hunts/pages/ViewHunt'));
const CreateHunt = lazy(() => import('~/pages/Hunts/pages/CreateHunt'));
const Moderate = lazy(() => import('~/pages/Moderate'));
const Users = lazy(() => import('~/pages/Users'));
const EditUser = lazy(() => import('~/pages/Users/pages/EditUser'));
const Events = lazy(() => import('~/pages/Events'));
const EditEvent = lazy(() => import('~/pages/Events/pages/EditEvent'));
const CreateEvent = lazy(() => import('~/pages/Events/pages/CreateEvent'));
const EditEventKind = lazy(() => import('~/pages/Events/pages/EditEventKind'));
const CreateEventKind = lazy(() =>
  import('~/pages/Events/pages/CreateEventKind')
);
const Dungeons = lazy(() => import('~/pages/Dungeons'));
const EditDungeon = lazy(() => import('~/pages/Dungeons/pages/EditDungeon'));
const CreateDungeon = lazy(() =>
  import('~/pages/Dungeons/pages/CreateDungeon')
);
const EditDungeonGroup = lazy(() =>
  import('~/pages/Dungeons/pages/EditDungeonGroup')
);
const CreateDungeonGroup = lazy(() =>
  import('~/pages/Dungeons/pages/CreateDungeonGroup')
);
const Items = lazy(() => import('~/pages/Items'));
const CreateItem = lazy(() => import('~/pages/Items/pages/CreateItem'));
const EditItem = lazy(() => import('~/pages/Items/pages/EditItem'));
const CreateItemCategory = lazy(() =>
  import('~/pages/Items/pages/CreateItemCategory')
);
const EditItemCategory = lazy(() =>
  import('~/pages/Items/pages/EditItemCategory')
);
const Creatures = lazy(() => import('~/pages/Creatures'));
const EditCreature = lazy(() => import('~/pages/Creatures/pages/EditCreature'));
const CreateCreature = lazy(() =>
  import('~/pages/Creatures/pages/CreateCreature')
);
const Channels = lazy(() => import('~/pages/Channels'));
const EditChannel = lazy(() => import('~/pages/Channels/pages/EditChannel'));
const CreateChannel = lazy(() =>
  import('~/pages/Channels/pages/CreateChannel')
);
const Changelog = lazy(() => import('~/pages/Changelog'));

type LoadingProps = { layout: React.ElementType };

const Loading = ({ layout: Layout }: LoadingProps) => (
  <Layout>
    <LoadingPage />
  </Layout>
);

const Routes = () => {
  return (
    <Suspense fallback={<Loading layout={BlankLayout} />}>
      <Switch>
        <PrivateRoute
          path={['/', '/moderate/hunts']}
          exact
          layout={DefaultLayout}
          component={Moderate}
          defaultTab="pending-hunts"
        />
        <PrivateRoute
          path="/changelog"
          exact
          layout={DefaultLayout}
          component={Changelog}
        />
        <PrivateRoute
          path="/hunts"
          exact
          layout={DefaultLayout}
          component={Hunts}
          defaultTab="all"
        />
        <PrivateRoute
          path="/hunts/new"
          exact
          layout={DefaultLayout}
          component={CreateHunt}
        />
        <PrivateRoute
          path="/hunts/:id"
          exact
          layout={DefaultLayout}
          component={ViewHunt}
        />
        <PrivateRoute
          path="/hunts/:id/edit"
          exact
          layout={DefaultLayout}
          component={EditHunt}
        />
        <PrivateRoute
          path="/moderate/hunts/:id"
          layout={DefaultLayout}
          component={PendingHunt}
        />
        <PrivateRoute
          path="/moderate/comments/pending"
          exact
          layout={DefaultLayout}
          component={Moderate}
          defaultTab="pending-comments"
        />
        <PrivateRoute
          path="/moderate/comments/reported"
          exact
          layout={DefaultLayout}
          component={Moderate}
          defaultTab="reported-comments"
        />
        <PrivateRoute
          path="/moderate/suggestions"
          exact
          layout={DefaultLayout}
          component={Moderate}
          defaultTab="edit-suggestions"
        />
        <PrivateRoute
          path="/moderate/suggestions/:id"
          exact
          layout={DefaultLayout}
          component={Moderate}
          defaultTab="edit-suggestions"
        />
        <PrivateRoute
          path="/dungeons"
          exact
          layout={DefaultLayout}
          component={Dungeons}
        />
        <PrivateRoute
          path="/dungeons/new"
          exact
          layout={DefaultLayout}
          component={CreateDungeon}
        />
        <PrivateRoute
          path="/dungeons/groups"
          exact
          layout={DefaultLayout}
          component={Dungeons}
          defaultTab="groups"
        />
        <PrivateRoute
          path="/dungeons/groups/new"
          exact
          layout={DefaultLayout}
          component={CreateDungeonGroup}
        />
        <PrivateRoute
          path="/dungeons/groups/:id"
          layout={DefaultLayout}
          component={EditDungeonGroup}
        />
        <PrivateRoute
          path="/dungeons/:id/sections"
          layout={DefaultLayout}
          component={EditDungeon}
          defaultTab="sections"
        />
        <PrivateRoute
          path="/dungeons/:id"
          layout={DefaultLayout}
          component={EditDungeon}
        />
        <PrivateRoute
          path="/users"
          exact
          layout={DefaultLayout}
          component={Users}
        />
        <PrivateRoute
          path="/users/:id"
          layout={DefaultLayout}
          component={EditUser}
        />
        <PrivateRoute
          path="/events"
          exact
          layout={DefaultLayout}
          component={Events}
        />
        <PrivateRoute
          path="/events/kinds"
          exact
          layout={DefaultLayout}
          component={Events}
          defaultTab="kinds"
        />
        <PrivateRoute
          path="/events/kinds/new"
          exact
          layout={DefaultLayout}
          component={CreateEventKind}
        />
        <PrivateRoute
          path="/events/kinds/:id"
          layout={DefaultLayout}
          component={EditEventKind}
        />
        <PrivateRoute
          path="/events/new"
          exact
          layout={DefaultLayout}
          component={CreateEvent}
        />
        <PrivateRoute
          path="/events/:id"
          layout={DefaultLayout}
          component={EditEvent}
        />
        <PrivateRoute
          path="/creatures"
          exact
          layout={DefaultLayout}
          component={Creatures}
        />
        <PrivateRoute
          path="/creatures/new"
          exact
          layout={DefaultLayout}
          component={CreateCreature}
        />
        <PrivateRoute
          path="/creatures/:id/loots"
          layout={DefaultLayout}
          component={EditCreature}
          defaultTab="loots"
        />
        <PrivateRoute
          path="/creatures/:id"
          layout={DefaultLayout}
          component={EditCreature}
        />
        <PrivateRoute
          path="/youtube-channels"
          exact
          layout={DefaultLayout}
          component={Channels}
        />
        <PrivateRoute
          path="/youtube-channels/new"
          exact
          layout={DefaultLayout}
          component={CreateChannel}
        />
        <PrivateRoute
          path="/youtube-channels/:id"
          layout={DefaultLayout}
          component={EditChannel}
        />
        <PrivateRoute
          path="/items"
          exact
          layout={DefaultLayout}
          component={Items}
        />
        <PrivateRoute
          path="/items/categories"
          exact
          layout={DefaultLayout}
          component={Items}
          defaultTab="categories"
        />
        <PrivateRoute
          path="/items/categories/new"
          exact
          layout={DefaultLayout}
          component={CreateItemCategory}
        />
        <PrivateRoute
          path="/items/categories/:id"
          layout={DefaultLayout}
          component={EditItemCategory}
        />
        <PrivateRoute
          path="/items/supplies"
          exact
          layout={DefaultLayout}
          component={Items}
          defaultTab="supplies"
        />
        <PrivateRoute
          path="/items/required"
          exact
          layout={DefaultLayout}
          component={Items}
          defaultTab="required"
        />
        <PrivateRoute
          path="/items/new"
          exact
          layout={DefaultLayout}
          component={CreateItem}
        />
        <PrivateRoute
          path="/items/:id/loots"
          layout={DefaultLayout}
          component={EditItem}
          defaultTab="loots"
        />
        <PrivateRoute
          path="/items/:id"
          layout={DefaultLayout}
          component={EditItem}
        />
        <GuestRoute
          path="/login"
          exact
          layout={BlankLayout}
          component={Login}
        />
        <PrivateRoute layout={DefaultLayout} component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
