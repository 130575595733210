import React, { ComponentProps, ElementType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { StoreProps } from '~/types';

type GuestRouteProps = RouteProps & {
  component: ElementType;
  layout: ElementType;
};

const GuestRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}: GuestRouteProps) => {
  const csrf = useSelector((store: StoreProps) => store.user.csrf);

  const finalComponent = (props: ComponentProps<ElementType>) => {
    if (!csrf) {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }
    return <Redirect to="/" />;
  };

  return <Route {...rest} render={finalComponent} />;
};

export default GuestRoute;
