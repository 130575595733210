import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { ReactComponent as UserIcon } from '~/assets/images/svgs/solid/user.svg';
import { FontFamily } from '~/styles/variables';

export const Container = styled.div`
  position: relative;
  display: flex;
`;

export const Icon = styled(UserIcon)`
  width: 13px;
  fill: #fff;
`;

export const Dropdown = styled.div`
  width: 140px;
  background-color: #fff;
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #cacaca;

  &:before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    top: -7px;
    right: 15px;
    position: absolute;
  }
`;

const DropdownItem = css`
  padding: 7px 15px;
  font-weight: bold;
  color: #2d2d2d;
  font-size: 17px;
  font-family: ${FontFamily.DIN};
  cursor: pointer;
  background-color: #fff;
  transition: 0.2s all;

  &:hover {
    background-color: #c7c7c7;
    opacity: 1;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const DropdownLink = styled(Link)`
  ${DropdownItem}
`;

export const DropdownButton = styled.button`
  ${DropdownItem}
  margin: 0;
  border: 0;
  text-align: left;
`;
